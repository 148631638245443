import MySection from "../components/Section";
import MyButton from "../components/Button";
import MyHeader from "../components/Header";
import personAddIcon from "../resources/person-add.svg";
import arrowBarLeftIcon from "../resources/arrow-bar-left.svg";
import { useState } from "react";
import Loading from "../components/Loading";
import api from "../services/api";
import { useNavigate } from "react-router-dom";
import CurrencyInput from "react-currency-input";

function NewService(props) {
  const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState({ amount: "0.00" });
  const [valorFloat, setValorFloat] = useState(0.0);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleCodigoChange = (e) => {
    setCodigo(e.target.value);
  };

  const handleDescricaoChange = (e) => {
    setDescricao(e.target.value);
  };

  const handleValorChange = (event, maskedvalue, floatvalue) => {
    setValor({ amount: maskedvalue });
    setValorFloat(floatvalue);
  };

  const servicesFirebase = async (codigoProcurado) => {
    let quantidade = 0;
    await api
      .get("/cadastroserv/findAll", {})
      .then((response) => {
        const newArray = Object.entries(response.data).map(([id, item]) => ({
          id,
          ...item,
        }));
        const filteredArray = newArray.filter(
          (item) => item.codigo === codigoProcurado
        );
        quantidade = filteredArray.length;
      })
      .catch(() => {});
    return quantidade;
  };

  const writeUserData = async (parametro) => {
    const qtd = await servicesFirebase(parametro.codigo);
    if (qtd > 0) {
      setIsLoading(false);
      navigate("/services", {});
      return alert("Código já utilizado!");
    }

    const service = {
      codigo: parametro.codigo,
      descricao: parametro.descricao,
      valor: parametro.valor,
      data: parametro.date,
    };

    api
      .post("/cadastroserv/create", {
        ...service,
      })
      .then((response) => {
        setIsLoading(false);
        navigate("/services", {});
        return alert("Serviço Adicionado!");
      })
      .catch((err) => {
        alert("Falha ao incluir serviço!");
      });
  };

  const adicionarServico = (codigo, descricao, valor) => {
    const novoServico = {
      date: Date.now(),
      codigo: codigo,
      descricao: descricao,
      valor: valor,
    };

    return novoServico;
  };

  const validateData = () => {
    if (!codigo) {
      return alert("Código não preenchido!");
    } else if (!descricao) {
      return alert("Descrição não informada!");
    } else if (!valorFloat) {
      return alert("Valor não informado!");
    }
    setIsLoading(true);
    const novoServico = adicionarServico(codigo, descricao, valorFloat);
    writeUserData(novoServico);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <MyHeader title="Cadastro de Serviços" />
      <form>
        <div className="p-5 flex flex-wrap -mx-4">
          <div className="w-full md:w-1/3 px-4 mb-2">
            <label htmlFor="codigo" className="block mb-1">
              Codigo:
            </label>
            <input
              type="number"
              id="codigo"
              name="codigo"
              className="w-full border rounded py-2 px-3"
              value={codigo}
              onChange={handleCodigoChange}
            />
          </div>
          <div className="w-full md:w-1/3 px-4 mb-2">
            <label htmlFor="descricao" className="block mb-1">
              Descrição:
            </label>
            <input
              type="text"
              id="descricao"
              name="descricao"
              className="w-full border rounded py-2 px-3"
              value={descricao}
              onChange={handleDescricaoChange}
            />
          </div>
          <div className="w-full md:w-1/3 px-4 mb-2">
            <label htmlFor="valor" className="block mb-1">
              Valor
            </label>
            <CurrencyInput
              precision="2"
              prefix="R$ "
              decimalSeparator=","
              thousandSeparator="."
              className="w-full border rounded py-2 px-3"
              value={valor.amount}
              onChangeEvent={handleValorChange}
            />
          </div>

          <div className="w-full md:w-1/3 px-4 mb-2 bottom-0">
            <label htmlFor="">&nbsp;</label>
            <MySection>
              <MyButton
                to="/services"
                alt="Voltar"
                source={arrowBarLeftIcon}
                nome="Voltar"
              />
              <MyButton
                onClick={validateData}
                to=""
                alt="Salvar"
                source={personAddIcon}
                nome="Salvar"
              />
            </MySection>
          </div>
        </div>
      </form>
    </div>
  );
}

export default NewService;
