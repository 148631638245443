import React, { useState, useEffect, useRef } from 'react';
import {Colors} from '../utils/Colors';

const MonthCarousel = ({ onMonthClick, months, initialIndex }) => {
  const scrollViewRef = useRef(null);
  const [selectedMonthIndex, setSelectedMonthIndex] = useState(initialIndex);

  useEffect(() => {
    handleMonthClick(initialIndex - 1);
  }, [initialIndex]);

  const handleMonthClick = (index) => {
    setSelectedMonthIndex(index);
    const selectedMonth = months[index];
    scrollToSelectedMonth(index);
    onMonthClick(selectedMonth);
  };

  const scrollToSelectedMonth = (index) => {
    if (scrollViewRef.current) {
      const offset = index > 0 ? index * 100 : 0;
      scrollViewRef.current.scrollTo({ left: offset, behavior: 'smooth' });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.white,
        overflowX: 'auto',
      }}
      ref={scrollViewRef}
    >
      {months.map((chave, index) => {
        const mes = chave.substr(0, 2);
        const ano = chave.substr(2, 4);
        const dataFormatada = `${mes}/${ano}`;

        return (
          <button
            key={index}
            style={{
              padding: '10px',
              borderRadius: '5px',
              margin: '5px',
              backgroundColor: index === selectedMonthIndex ? Colors.terciaryColor : Colors.dark,
            }}
            onClick={() => handleMonthClick(index)}
          >
            <span
              style={{
                color: '#F3F3F3',
                fontSize: '16px',
              }}
            >
              {dataFormatada}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default MonthCarousel;
