import { useLocation } from 'react-router-dom';
import MyButton from '../components/Button';
import MyHeader from '../components/Header';
import boxarrowrightIcon from '../resources/box-arrow-right.svg';
import './Privacidade.css';

// Política de Privacidade em formato de texto
const politicaDePrivacidade = `
**Política de Privacidade do Aplicativo Gerencie: Vendas e Clientes**

Esta Política de Privacidade descreve como o aplicativo "Gerencie: Vendas e Clientes" coleta, usa e compartilha informações pessoais dos usuários, em conformidade com a Lei Geral de Proteção de Dados (LGPD) (Lei nº 13.709/2018).

**1. Informações Pessoais Coletadas**

O aplicativo Gerencie: Vendas e Clientes pode coletar as seguintes informações pessoais dos usuários:

- Nome
- Endereço de e-mail
- Endereço físico
- Número de telefone
- Informações de pagamento
- Informações demográficas, como idade, gênero, preferências e interesses
- Informações de login (nome de usuário, senha, etc.)

**2. Como Coletamos Informações**

As informações pessoais podem ser coletadas de diversas maneiras, incluindo:

- Quando os usuários se registram no aplicativo Gerencie: Vendas e Clientes
- Quando os usuários preenchem formulários no aplicativo Gerencie: Vendas e Clientes
- Quando os usuários fazem transações no aplicativo Gerencie: Vendas e Clientes
- Quando os usuários participam de pesquisas ou promoções no aplicativo Gerencie: Vendas e Clientes
- Através do uso de cookies e tecnologias similares

**3. Como Usamos as Informações**

As informações pessoais coletadas pelo aplicativo Gerencie: Vendas e Clientes podem ser usadas para os seguintes fins:

- Personalizar a experiência do usuário no aplicativo Gerencie: Vendas e Clientes
- Processar transações realizadas no aplicativo Gerencie: Vendas e Clientes
- Enviar informações, atualizações e comunicações relacionadas ao serviço do aplicativo Gerencie: Vendas e Clientes
- Responder a consultas, perguntas ou solicitações dos usuários no aplicativo Gerencie: Vendas e Clientes
- Melhorar os produtos e serviços oferecidos pelo aplicativo Gerencie: Vendas e Clientes
- Realizar pesquisas e análises de mercado no aplicativo Gerencie: Vendas e Clientes

**4. Compartilhamento de Informações Pessoais**

O aplicativo Gerencie: Vendas e Clientes não compartilha informações pessoais dos usuários com terceiros, exceto quando exigido por lei ou quando expressamente autorizado pelo usuário.

**5. Cookies e Tecnologias Semelhantes**

O aplicativo Gerencie: Vendas e Clientes pode utilizar cookies e tecnologias similares para melhorar a experiência do usuário e coletar informações sobre o uso do aplicativo.

**6. Segurança**

Implementamos medidas de segurança apropriadas para proteger as informações pessoais dos usuários e garantir sua confidencialidade, em conformidade com a LGPD.

**7. Acesso e Controle**

Os usuários têm o direito de acessar, corrigir, atualizar ou excluir suas informações pessoais a qualquer momento, de acordo com a LGPD.

**8. Alterações nesta Política de Privacidade**

Reservamos o direito de atualizar esta política de privacidade a qualquer momento. As alterações serão disponibilizadas no aplicativo Gerencie: Vendas e Clientes e notificadas aos usuários, conforme exigido pela LGPD.


Esta política de privacidade do aplicativo Gerencie: Vendas e Clientes foi atualizada pela última vez em 30/08/2023.
`;

function Privacidade(props) {
    let { state } = useLocation();
    let back = "/";
    
    if (state !== null) {
        back = state.rotaBack;
    }

    return (
        <>
            <MyHeader title="Política de Privacidade" />
            <div className='content'>
                <pre>{politicaDePrivacidade}</pre>
            </div>
            <MyButton to={back} alt="Voltar" source={boxarrowrightIcon} nome="Voltar" />
        </>
    )
};

export default Privacidade;
