import React, { useState } from "react";
import MyButton from "./Button";
import personAddIcon from "../resources/person-add.svg";
import checkIcon from "../resources/check2-all.svg";
import baclIcon from "../resources/arrow-bar-left.svg";

import MyImage from "./Imagem";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import analytics from "../services/firebase";
import { logEvent } from "firebase/analytics";

function LoginForm(props) {
  let { loginUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const logo = require("../resources/logo.jpeg");
  // const logo = 'https://play-lh.googleusercontent.com/eOY8wAPpacpkr61jLgC6G9nY2ncIcI-5DiDilFRpdNfPT5YhcU7KWMVE3wk_o5NNc_oA=w480-h960-rw';
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const login = async (email, senha) => {
    const response = await loginUser(email, senha);
    console.log(response);
    setIsLoading(false);
    if (response.user.stsTokenManager.accessToken) {
      logEvent(analytics, "login_success");
      navigate("/home");
    }
  };

  const validateData = () => {
    if (!email) {
      return alert("Email não preenchido!");
    } else if (!password) {
      return alert("Senha não informada!");
    }
    setIsLoading(true);
    login(email, password);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className="bg-gray-900 h-screen w-screen">
      <form
        className="max-w-lg mx-auto mt-4 p-6 bg-white rounded-lg shadow-md"
        name="login_form"
      >
        <fieldset>
          <div className="flex justify-between"> 
            <MyImage src={logo} alt="logo" height={160} width={160} />
            <section>
                <h2>Seu novo app chegou</h2>
                <h2>Ainda não conhece? baixe já agora!</h2>
                <h2>Novas funcionalidades pra você!</h2>

                <a
                href="https://play.google.com/store/apps/details?id=com.gerencie&hl=en_US"
                aria-label="Download"
                className="inline-block bg-black text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                >
                <span>Download</span>
                </a>
                <h2>Para acesso Minha Ordem de Serviço, selecione Acessar Versão Antiga</h2>
            </section>
          </div>
          <div className="mb-4" />
          <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
            Email/Login:
          </label>
          <input
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            id="email"
            placeholder="Digite seu email/login"
            value={email}
            onChange={handleEmailChange}
          />
          <br />
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="password"
          >
            Senha:
          </label>
          <input
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            id="password"
            placeholder="Digite sua senha"
            value={password}
            onChange={handlePasswordChange}
          />
          <br />
          <div className="flex items-center justify-center">
            <MyButton
              to="http://gerenciadorweb.com.br:8083"
              alt="Antigo"
              source={baclIcon}
              nome="Acessar Versão Antiga"
            />
            <MyButton
              to="/register"
              alt="Register"
              source={personAddIcon}
              nome="Cadastro"
            />
            <MyButton
              onClick={validateData}
              alt="Login"
              source={checkIcon}
              nome="Login WEB"
            />
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default LoginForm;
