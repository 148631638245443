import './RegisterForm.css';
import React, { useState } from 'react';
import MyButton from './Button';
import baclIcon from '../resources/arrow-bar-left.svg';
import checkIcon from '../resources/check2-all.svg';
import MyImage from './Imagem';
import api from "../services/api";
import { useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

function RegisterForm(props) {
    const navigate = useNavigate();
    //const logo = 'https://play-lh.googleusercontent.com/10RVls3lohQiKhwqcSc6MZohNhguDTPiuPrZ-N3TG6cC4hPPr9PuhsruSXwFwUOCTPI=w240-h480-rw';
    const logo = require('../resources/logo.jpeg');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const labelStyle = "block text-gray-700 font-bold mb-2";
    const inputStyle = "appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";
    const formStyle = "max-w-lg mx-auto mt-4 p-6 bg-white rounded-lg shadow-md";

    const createUser = (email, senha) => {
        api
            .post("/auth/register", {
                email: email,
                senha: senha
            })
            .then((response) => {
                alert("Novo usuário criado com sucesso!");
                navigate("/login");
            })
            .catch((err) => {
                alert("ops! ocorreu um erro" + err);
            });
    }

    const validateData = () => {
        if (!email) {
            return alert("Email não preenchido!");
        } else if (!password) {
            return alert("Senha não informada!");
        } else if (passwordConfirm !== password) {
            return alert("Senhas não são iguais!");
        }
        createUser(email, password);
        // navigate('/home');
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handlePasswordConfirmChange = (e) => {
        setPasswordConfirm(e.target.value);
    }

    return (
        <div className="bg-gray-900 h-screen w-screen">
            <form className={formStyle} name="register_form">
                <fieldset>
                    <MyImage src={logo} alt="logo" height={160} width={160} />
                    <div className="mb-4" />
                    <label className={labelStyle} htmlFor="email">Email/Login:</label>
                    <input className={inputStyle} type="email" id="email" placeholder="Digite seu email/login" value={email} onChange={handleEmailChange} /><br />
                    <label className={labelStyle} htmlFor="password">Senha:</label>
                    <input className={inputStyle} type="password" id="password" placeholder="Digite sua senha" value={password} onChange={handlePasswordChange} /><br />
                    <label className={labelStyle} htmlFor="password">Confirmação de Senha:</label>
                    <input className={inputStyle} type="password" id="passwordConfirm" placeholder="Confirme sua senha" value={passwordConfirm} onChange={handlePasswordConfirmChange} /><br />
                    <div className="flex items-center justify-center">
                            <MyButton to="/login" alt="Voltar" source={baclIcon} nome="Voltar" />
                            <MyButton onClick={validateData} alt="Cadastrar" source={checkIcon} nome="Cadastrar" />
                    </div>
                </fieldset>
            </form>
        </div>
    )
};

export default RegisterForm;