import React from "react";
import { FixedSizeList } from "react-window";
import { useEffect } from "react";

const ListItem = ({ data, index, style, onButtonClick }) => {
  const product = data[index];
  product.valorUnit = product.valor;
  
    return (
      <div style={style} className="flex items-center justify-between p-3 mb-2 rounded">
        <div>
          <p className="text-lg font-bold">{product.descricao}</p>
          <p>{product.valorUnit ? `Valor: R$ `+product.valorUnit : undefined }</p>
        </div>
        <button
          type="button"
          className="p-2 h-10 bg-blue-600 text-white rounded hover:bg-red-600"
          onClick={() => onButtonClick(product)}
        >
          +
        </button>
      </div>
    );
  };

function FlatList(props) {
  const { data, onButtonClick } = props;

  return (
    <FixedSizeList
      height={250} // Altura da lista
      itemCount={data?.length} // Número total de itens
      itemSize={50} // Altura de cada item
      width={300} // Largura da lista
      itemData={data} // Passando os dados como propriedade itemData
    >
      {(listProps) => <ListItem {...listProps} onButtonClick={onButtonClick ? onButtonClick : () => {}} />}
    </FixedSizeList>
  );
}

export default FlatList;
