import MySection from "../components/Section";
import MyButton from "../components/Button";
import MyHeader from "../components/Header";
import arrowBarLeftIcon from "../resources/arrow-bar-left.svg";
import ComboBox from "react-responsive-combo-box";
import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import FlatListOrcamento from "../components/FlatListOrcamento";
import api from "../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import "react-responsive-combo-box/dist/index.css";

function ShowVenda(props) {
  const [clientes, setClientes] = useState("");
  const [showBox, setShowBox] = useState(true);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const {state} = useLocation();
  const [productsSale, setProductsSale] = useState([]);
  const [servicesSale, setServicesSale] = useState([]);

  const navigate = useNavigate();
  console.log(state);

  useEffect(() => {
    setProductsSale(state.produtos);
    setServicesSale(state.servicos);
    setTotal(state.valor);
    setClientes(state.nomeCliente);
    //findAllCustomers();
    //findAllProducts();
    //findAllServices();
  }, [state]);

    // useEffect(() => {
    //   setTotal(valorProvisorio);
    // }, [productsSale, servicesSale]);

  const showToastWithGravity = (message) => {
    alert(message);
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <MyHeader title="Detalhes de Vendas" />
      <form>
        <div>      
          <div className="flex justify-between"> 
          <div className="w-full md:w-1/3 px-4 mb-2">
            <label htmlFor="cliente" className="block mb-1">
              Cliente: {clientes}
            </label>
          </div>

            <div className="w-full md:w-1/3 px-2 mb-2">
              <div className="bg-blue-100 p-4 text-black rounded-md shadow-md">
                <p className="text-3xl font-bold mb-4">Orçamento:</p>
                <div className="w-full md:w-1/4 px-2 mb-2">
                  <div>
                    <label htmlFor="produtos" className="block mb-1">
                      Produtos:
                    </label>
                    <FlatListOrcamento data={productsSale} />
                  </div>

                  <div>
                    <label htmlFor="servicos">Serviços:</label>
                    <FlatListOrcamento data={servicesSale} />
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-blue-200 p-4 text-black rounded-md shadow-md mb-2">
              <p className="text-3xl font-bold mb-4">Valor Total</p>
              <div className="flex items-center justify-center">
                <span className="text-xl">R$</span>
                <span className="text-6xl ml-4">{total}</span>
              </div>
            </div>
            
          </div>

          <div className="fixed bottom-0 right-0 flex items-center justify-center flex-row-reverse p-4">
            <label htmlFor="">&nbsp;</label>
            <MySection>
              <MyButton
                to="/home"
                alt="Voltar"
                source={arrowBarLeftIcon}
                nome="Voltar"
              />
            </MySection>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ShowVenda;
