import MySection from "../components/Section";
import MyButton from "../components/Button";
import MyHeader from "../components/Header";
import personAddIcon from "../resources/person-add.svg";
import arrowBarLeftIcon from "../resources/arrow-bar-left.svg";
import CurrencyInput from "react-currency-input";
import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import api from "../services/api";
import { useLocation, useNavigate } from "react-router-dom";

function EditService(props) {
  let { state } = useLocation();

  const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState({ amount: state.valor });
  const [isLoading, setIsLoading] = useState(false);
  const [valorFloat, setValorFloat] = useState(0.0);

  const navigate = useNavigate();

  useEffect(() => {
    if (state) {
      console.log(state);
        setCodigo(state.codigo);
        setDescricao(state.descricao);
        setValorFloat(state.valor);
    }   
  }, [state]);

  const handleCodigoChange = (e) => {
    setCodigo(e.target.value);
  };

  const handleDescricaoChange = (e) => {
    setDescricao(e.target.value);
  };

  const handleValorChange = (event, maskedvalue, floatvalue) => {
    setValor({ amount: maskedvalue });
    setValorFloat(floatvalue);
  };

  const editService = async () => {

    setIsLoading(true);
    const service = {
      idServico: state.id,
      codigo,
      descricao,
      valor: valorFloat
  }
    api
      .post(`/cadastroserv/update`, {
          ...service
      })
      .then((response) => {
          setIsLoading(false);
          alert('Serviço Alterado com Sucesso!');
          navigate("/services");
        })
      .catch((err) => {
          alert("ops! ocorreu um erro" + err);
      });
  };

  const adicionarServico = (codigo, descricao, valor) => {
    const novoServico = {
      date: Date.now(),
      codigo: codigo,
      descricao: descricao,
      valor: valor,
    };

    return novoServico;
  };

  const validateData = () => {
    if (!codigo) {
      return alert("Código não preenchido!");
    } else if (!descricao) {
      return alert("Descrição não informada!");
    } else if (!valorFloat) {
        return alert("Valor não informado!");
    }
    setIsLoading(true);
    const novoServico = adicionarServico(codigo, descricao, valorFloat);
    editService(novoServico);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <MyHeader title="Edição de Serviços" />
      <form>
        <div className="p-5 flex flex-wrap -mx-4">
          <div className="w-full md:w-1/3 px-4 mb-2">
            <label htmlFor="codigo" className="block mb-1">
              Codigo:
            </label>
            <input
              type="text"
              id="codigo"
              name="codigo"
              className="w-full border rounded py-2 px-3"
              value={codigo}
              onChange={handleCodigoChange}
            />
          </div>
          <div className="w-full md:w-1/3 px-4 mb-2">
            <label htmlFor="descricao" className="block mb-1">
              Descrição:
            </label>
            <input
              type="text"
              id="descricao"
              name="descricao"
              className="w-full border rounded py-2 px-3"
              value={descricao}
              onChange={handleDescricaoChange}
            />
          </div>
          <div className="w-full md:w-1/3 px-4 mb-2">
            <label htmlFor="valor" className="block mb-1">
              Valor
            </label>
            <CurrencyInput
              precision="2"
              prefix="R$ "
              decimalSeparator=","
              thousandSeparator="."
              className="w-full border rounded py-2 px-3"
              value={valor.amount}
              onChangeEvent={handleValorChange}
            />
          </div>
          <div className="w-full md:w-1/3 px-4 mb-2 bottom-0">
            <label htmlFor="">&nbsp;</label>
            <MySection>
              <MyButton
                to="/services"
                alt="Voltar"
                source={arrowBarLeftIcon}
                nome="Voltar"
              />
              <MyButton
                onClick={validateData}
                to=""
                alt="Salvar"
                source={personAddIcon}
                nome="Salvar"
              />
            </MySection>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditService;
