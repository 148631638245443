import { useLocation } from 'react-router-dom';
import MyButton from '../components/Button';
import MyHeader from '../components/Header';
import boxarrowrightIcon from '../resources/box-arrow-right.svg';
import './About.css';

function About(props) {
    let { state } = useLocation();
    let back = "/";
    
    if(state !== null){
        back = state.rotaBack;
    }

    return (
        <>
            <MyHeader title="Sobre o Sistema de Gerenciamento de Vendas" />
            <div className='content'>
                <br />
                <p>O Sistema de Gerenciamento de Vendas é uma aplicação web que tem como objetivo auxiliar empresas a gerenciarem
                    as vendas e ordens de serviço que recebem. Com ele é possível cadastrar novas vendas e 
                    acompanhar o status de cada uma delas.</p>
                <br />
                <p>Desenvolvido utilizando tecnologias web modernas como HTML5, CSS3 e JavaScript. Ele é compatível com os
                    principais navegadores web e pode ser acessado a partir de qualquer dispositivo conectado à internet.</p>
                <br />
                <p>Dúvidas ou sugestões podem ser enviadas para applrsistemas@gmail.com</p>
            </div>
            <section>
                <h2>Conheça nosso app!</h2>
                <a href="https://play.google.com/store/apps/details?id=com.gerencie&hl=en_US"
                    aria-label="Baixe o aplicativo na Play Store"
                    className="inline-block bg-black text-white font-bold py-2 px-4 rounded hover:bg-blue-700">
                    <span>Baixar na Play Store</span>
                </a>
            </section>
            <MyButton to={back} alt="Voltar" source={boxarrowrightIcon} nome="Voltar" />
        </>
    )
};

export default About;