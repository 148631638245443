export const Colors = {
    primary: '#0084ff',
    primaryLight: '#4da1ff',
    primary50: '#0084ff50',
    primary25: '#0084ff25',
    primary10: '#0084ff10',
    primary05: '#0084ff05',
    secondary: '#ff6f00',
    transparent: 'rgba(0,0,0,0)',
    typography: '#000',
    black: '#000',
    black75: 'rgba(0,0,0,0.75)',
    black50: 'rgba(0,0,0,0.5)',
    white: '#F3F3F3',
    white95: 'rgba(255,255,255,0.95)',
    white90: 'rgba(255,255,255,0.9)',
    white75: 'rgba(255,255,255,0.75)',
    white50: 'rgba(255,255,255,0.5)',
    white006: 'rgba(255,255,255,0.06)',
    gray: '#ccc',
    placeholder: '#4F555A',
    disabled: '#E5E5E5',
    error: '#FF0000',
    border: '#eee',
    background: '#F6F6F6',
    kE5AFAF: '#E5AFAF',
    kC4BCFF: '#C4BCFF',
    kC2D8BE: '#C2D8BE',
    k222222: '#222222',
    k5BB318: '#5BB318',
    kFF7A51: '#FF7A51',
    kFFDB5C: '#FFDB5C',
    kDDA82A0A: '#DDA82A0A',
    kDFDFDF: '#DFDFDF',
    k8E8E8E: '#8E8E8E',
    kB8FF8D: '#B8FF8D',
    kC2C2C2: '#C2C2C2',
    kF8F8F8: '#F8F8F8',
    kE6EEFA: '#E6EEFA',
    k6C7A9C: '#6C7A9C',
    kEA047E: '#EA047E',
    kFF6D28: '#FF6D28',
    kFCE700: '#FCE700',
    k00F5FF: '#00F5FF',
    k150050: '#150050',
    k3F0071: '#3F0071',
    kFB2576: '#FB2576',
    kE0144C: '#E0144C',
    kE0144C50: '#E0144C50',
    k54B435: '#54B435',
    blueblack: '#1C315E',
    primaryColor: '#242526',
    secondaryColor: '#556270',
    terciaryColor: '#F26938',
    backgroundDefault: '#F3F3F3',
    dark: '#242526',
    dark2: '#010D26',
    dark3: '#011640',
    dark4: '#1A2126',
    orange1: '#F2522E',
    orange2: '#F26938',
    yellow: '#EBF20C',
    blue: '#3498db',
    blue2: '#BBCDF2',
    blue3: '#9BB8F2',
    blue4: '#527AF2',
    blue5: '#0742F2'
  }
  
  export const DarkColors = {
    typography: '#F3F3F3',
  }
  