import React from 'react'
import UserLogout from '../components/UserLogout';

const ProtectedRoute = ({ children }) => {
    const userInfo = localStorage.getItem('userInfo');

    if (!userInfo) {
        return (
            <UserLogout />
        )
    } else {
        return children;
    }
}

export default ProtectedRoute;
