import { useEffect, useState } from "react";

const Toast = ({ message, duration }) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(false);
        }, duration * 1000);

        return () => clearTimeout(timer);
    }, [duration]);

    return (
        <div
            className={`${show ? "bottom-10" : "-bottom-full"
                } right-10 fixed z-50 transition-all duration-300 ease-in-out`}
        >
            <div className="bg-blue-800 rounded-md p-4 shadow-md">
                <p className="text-white text-sm">{message}</p>
            </div>
        </div>
    );
};

export default Toast;
