import MySection from '../components/Section';
import MyButton from '../components/Button';
import MyHeader from '../components/Header';
import personAddIcon from '../resources/person-add.svg';
import arrowBarLeftIcon from '../resources/arrow-bar-left.svg';
import InputMask from 'react-input-mask';
import { useEffect, useState } from 'react';
import Loading from '../components/Loading';
import api from "../services/api";
import { useLocation, useNavigate } from 'react-router-dom';

function EditCustomer(props) {
    const [cidade, setCidade] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [endereco, setEndereco] = useState('');
    const [nome, setNome] = useState('');  
    const [isLoading, setIsLoading] = useState(false);
    let { state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        api
            .get(`/cliente/find/${state.id}`, {
            })
            .then((response) => {
                setCidade(response.data.cidade);
                setEmail(response.data.email);
                setTelefone(response.data.telefone);
                setEndereco(response.data.endereco);
                setNome(response.data.nome);
            })
            .catch((err) => {
                alert("ops! ocorreu um erro" + err);
            });
    }, [state.id])

    const handleCidadeChange = (e) => {
        setCidade(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleTelefoneChange = (e) => {
        setTelefone(e.target.value);
    }

    const handleEnderecoChange = (e) => {
        setEndereco(e.target.value);
    }

    const handleNomeChange = (e) => {
        setNome(e.target.value);
    }

    const editCustomer = () => {
        const customer = {
            cidade,
            email,
            telefone,
            endereco,
            nome,
            ativo: "S"
        }
        
        api
            .put(`/cliente/update/${state.id}`, {
                ...customer
            })
            .then((response) => {
                setIsLoading(false);
                alert("cliente editado com sucesso!");
                navigate("/customers");
            })
            .catch((err) => {
                alert("ops! ocorreu um erro" + err);
            });
    }

    const validateData = () => {
        if (!telefone) {
            return alert("Telefone não preenchido!");
        } else if (!nome) {
            return alert("Nome não informado!");
        }
        setIsLoading(true);  
        editCustomer();      
    }

    return (
        isLoading ? <Loading /> :
        <div>
            <MyHeader title="Edição de Cliente" />
            <form>
                <div className="p-5 flex flex-wrap -mx-4">
                    
                        <div className="w-full md:w-1/3 px-4 mb-2">
                            <label htmlFor="cidade" className="block mb-1">Cidade:</label>
                            <input type="text" id="cidade" name="cidade" className="w-full border rounded py-2 px-3" 
                            value={cidade} onChange={handleCidadeChange}/>
                        </div>
                        <div className="w-full md:w-1/3 px-4 mb-2">
                            <label htmlFor="email" className="block mb-1">Email:</label>
                            <input type="email" id="email" name="email" className="w-full border rounded py-2 px-3" 
                            value={email} onChange={handleEmailChange}/>
                        </div>
                        <div className="w-full md:w-1/3 px-4 mb-2">
                            <label htmlFor="telefone" className="block mb-1">Telefone:</label>
                            <InputMask mask="(99) 99999-9999" type="tel" id="telefone" name="telefone" className="w-full border rounded py-2 px-3" 
                            value={telefone} onChange={handleTelefoneChange}/>
                        </div>
                        <div className="w-full md:w-1/3 px-4 mb-2">
                            <label htmlFor="endereco" className="block mb-1">Endereço:</label>
                            <input type="text" id="endereco" name="endereco" className="w-full border rounded py-2 px-3" 
                            value={endereco} onChange={handleEnderecoChange}/>
                        </div>
                        <div className="w-full md:w-1/3 px-4 mb-2">
                            <label htmlFor="nome" className="block mb-1">Nome:</label>
                            <input type="text" id="nome" name="nome" className="w-full border rounded py-2 px-3" 
                            value={nome} onChange={handleNomeChange}/>
                        </div>
                    
                    <div className="w-full md:w-1/3 px-4 mb-2 bottom-0">
                        <label htmlFor="">&nbsp;</label>
                        <MySection>
                            <MyButton to="/customers" alt="Voltar" source={arrowBarLeftIcon} nome="Voltar" />
                            <MyButton onClick={validateData} to="" alt="Salvar" source={personAddIcon} nome="Salvar" />
                        </MySection>
                    </div>
                </div>
            </form>
        </div>
    )
};

export default EditCustomer;