import './App.css';
import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error-page";
import Home from './routes/Home';
import Login from './routes/Login';
import Register from './routes/Register';
import Customers from './routes/Customers';
import Products from './routes/Products';
import ProductsChoice from './routes/ProductsChoice';
import Services from './routes/Services';
import About from './routes/About';
import Privacidade from './routes/Privacidade';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './routes/ProtectedRoute';
import NewCustomer from './routes/NewCustomer';
import NewProduct from './routes/NewProduct';
import NewService from './routes/NewService';
import EditCustomer from './routes/EditCustomer';
import EditProduct from './routes/EditProduct';
import EditService from './routes/EditService';
import NewVenda from './routes/NewVenda';
import ShowVenda from './routes/ShowVenda';
import ListVendas from './routes/ListVendas';
import ListVendasFaturadas from './routes/ListVendasFaturadas';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/home",
      element: <ProtectedRoute>
        <Home />
      </ProtectedRoute>,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/contato",
      element: <About />,
    },
    {
      path: "/privacidade",
      element: <Privacidade />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/customers",
      element: <ProtectedRoute>
        <Customers />
      </ProtectedRoute>,
    },
    {
      path: "/products",
      element: <ProtectedRoute>
        <Products />
      </ProtectedRoute>,
    },
    {
      path: "/productschoice",
      element: <ProtectedRoute>
        <ProductsChoice />
      </ProtectedRoute>,
    },
    {
      path: "/services",
      element: <ProtectedRoute>
        <Services />
      </ProtectedRoute>,
    },
    {
      path: "/newcustomer",
      element: <ProtectedRoute>
        <NewCustomer />
      </ProtectedRoute>,
    },
    {
      path: "/newproduct",
      element: <ProtectedRoute>
        <NewProduct />
      </ProtectedRoute>,
    },
    {
      path: "/newservice",
      element: <ProtectedRoute>
        <NewService />
      </ProtectedRoute>,
    },
    {
      path: "/editcustomer",
      element: <ProtectedRoute>
        <EditCustomer />
      </ProtectedRoute>,
    },
    {
      path: "/editproduct",
      element: <ProtectedRoute>
        <EditProduct />
      </ProtectedRoute>,
    },
    {
      path: "/editservice",
      element: <ProtectedRoute>
        <EditService />
      </ProtectedRoute>,
    },
    {
      path: "/listvendas",
      element: <ProtectedRoute>
        <ListVendas />
      </ProtectedRoute>,
    },
    {
      path: "/listvendasfaturadas",
      element: <ProtectedRoute>
        <ListVendasFaturadas />
      </ProtectedRoute>,
    },
    {
      path: "/newvenda",
      element: <ProtectedRoute>
        <NewVenda />
      </ProtectedRoute>,
    },
    {
      path: "/showvenda",
      element: <ProtectedRoute>
        <ShowVenda />
      </ProtectedRoute>,
    },
  ])

  return (
    <AuthProvider>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </AuthProvider>
  );
}

export default App;
