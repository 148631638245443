import { useEffect, useState } from 'react'
import api from "../services/api";
import { encode } from 'base-64';

const useAuth = () => {
    const [userLogged, setUserLogged] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userInfo = localStorage.getItem('userInfo');
        if(userInfo){
            const userInfoJson = JSON.parse(userInfo);
            const encodedEmail = encode(userInfoJson.user.email);
            api.defaults.headers.common['Authorization'] = `${userInfoJson.user}`;
            api.defaults.headers.common['UserId'] = `${encodedEmail}`;
            setUserLogged(true);
        }
        setLoading(false);
    }, []);

    const userLogout = async () => {
        try{
            await api
            .post("/auth/sair");
            
            setUserLogged(false);
            localStorage.removeItem('userInfo');
        }
        catch(e) {
            alert("Falha no logout!");
        }        
    };
    
    const loginUser = async (email, senha) => {
        try{
        const response = await api
        .post("/auth/login", {
            email: email,
            senha: senha
        })
        const data = await response.data;
        // const response = await fetch('http://89.116.214.208:3000/auth/login', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({email, senha})
        // });
        // const data = await response.json();
            if(data){
                localStorage.setItem('userInfo', JSON.stringify(data));
                const encodedEmail = encode(data.user.email);
                api.defaults.headers.common['Authorization'] = `${data}`;
                api.defaults.headers.common['UserId'] = `${encodedEmail}`;
                setUserLogged(true);
                return data;
            }        
        } catch(e) {
            alert("Falha no login, verifique o usuário e senha digitados!");
        }
    }

    return {userLogged, loading, loginUser, userLogout}
}

export default useAuth;
