import MyButton from '../components/Button';
import MyHeader from '../components/Header';
import './Products.css';
import personAddIcon from '../resources/person-add.svg';
import arrowBarLeftIcon from '../resources/arrow-bar-left.svg';
import { useEffect, useState } from 'react';
import api from "../services/api";
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router-dom';

function Products(props) {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        findAllProducts();
    }, []);

    const findAllProducts = () => {
        api
        .get("/cadastroprod/findAll", {
        })
        .then((response) => {
            const newArray = Object.entries(response.data).map(([id, item]) => ({
                id,
                ...item
            }));
            const filteredArray = newArray.filter((item) => item.inativo === "N");
            console.log(filteredArray);
            setProducts(filteredArray);
        })
        .catch((err) => {
          alert("ops! ocorreu um erro" + err);
        });
      };

    const data = {
        columns: [
            {
                label: 'Codigo',
                field: 'codigo',
                sort: 'asc',
                width: 250
            },
            {
                label: 'Descricao',
                field: 'descricao',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Valor R$',
                field: `valor`,
                sort: 'asc',
                width: 250
            },
            {
                label: 'Ação',
                field: 'acao',
                sort: 'asc',
                width: 250
            }
        ],
        rows:
            products
    };

    const handleClick = row => {
        navigate('/editproduct', {state: 
            {codigo: row.codigo, id: row.id, descricao: row.descricao, valor: row.valor}
        });
    };

    const deleteProduct = (row) => {
        api
            .delete(`/cadastroprod/remove/${row.id}`)
            .then(() => {
                alert("Produto excluido!");
                findAllProducts();
            })
            .catch((err) => {
                alert("ops! ocorreu um erro" + err);
            });
    };

    const handleDelete = (row) => {
        const confirmed = window.confirm(`Tem certeza que deseja excluir produto ${row.descricao} ?`);
        if (confirmed) {
            deleteProduct(row);
        }
    };

    const rows = data.rows.map(row => {
        row.acao =
            <div className="flex justify-between px-8 py-2">
                <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={() => handleClick(row)}>Editar</button>
                <button className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600" onClick={() => handleDelete(row)}>Excluir</button>
            </div>
        return row;
    });

    return (
        <div className="h-screen w-screen relative">
            <MyHeader title="Produtos" />
            <div>
                <MDBDataTable
                    className="max-xl: mx-2 mt-4 p-6 rounded-lg shadow-md"
                    scrollY
                    height="150vh"
                    bordered
                    responsive
                    paging={false}
                    data={{ ...data, rows }}
                />

                <div className="fixed bottom-0 right-0 flex items-center justify-center flex-row-reverse p-4">
                    <MyButton to="/newproduct" alt="Novo" source={personAddIcon} nome="Novo" />
                    <MyButton to="/home" alt="Voltar" source={arrowBarLeftIcon} nome="Voltar" />
                </div>
            </div>
        </div>
    )
};

export default Products;