import MyButton from '../components/Button';
import MySection from '../components/Section';
import personIcon from '../resources/person.svg';
import serviceIcon from '../resources/services.svg';
import productIcon from '../resources/products.svg';
import pluscirclefillIcon from '../resources/plus-circle-fill.svg';
import calendar2Icon from '../resources/calendar2.svg';
import calendar2checkIcon from '../resources/calendar2-check.svg';
import infocirclefillIcon from '../resources/info-circle-fill.svg';
import boxarrowrightIcon from '../resources/box-arrow-right.svg';
import MyHeader from '../components/Header';
// import { useLocation } from 'react-router-dom';
import './Home.css';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Toast from '../components/Toast';
import analytics from "../services/firebase";
import { logEvent } from "firebase/analytics";

function Home(props) {
    const { userLogout } = useContext(AuthContext);
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        logEvent(analytics, 'home_screen');
        setShowToast(true);
    }, []);

    const navigate = useNavigate();

    const logout = () => {
        userLogout();
        navigate('/');
    }

    // let { state } = useLocation();

    return (
        <div className="text-center">
            <MyHeader title="Escolha uma opção!"
                message="Olá, esta é uma plataforma online para gerenciar vendas de forma simples e eficiente." />
            <MySection>
                <MyButton to="/customers" alt="Clientes" source={personIcon} nome="Clientes" />
                <MyButton to="/products" alt="Produtos" source={productIcon} nome="Produtos" />
                <MyButton to="/services" alt="Serviços" source={serviceIcon} nome="Serviços" />
                <MyButton to="/newvenda" alt="Nova Venda" source={pluscirclefillIcon} nome="Nova Venda" />
                <MyButton to="/listvendas" alt="Abertas" source={calendar2Icon} nome="Abertas" />
                <MyButton to="/listvendasfaturadas" alt="Faturadas" source={calendar2checkIcon} nome="Faturadas" />
                <MyButton to="/contato" back="/home" alt="Sobre" source={infocirclefillIcon} nome="Sobre" />
                <MyButton onClick={logout} alt="Sair" source={boxarrowrightIcon} nome="Sair" />
            </MySection>
            {showToast && <Toast message="Bem vindo!" duration={3} />}
        </div>
    )
};

export default Home;
