import MyButton from '../components/Button';
import MyHeader from '../components/Header';
import './Customers.css';
import personAddIcon from '../resources/person-add.svg';
import arrowBarLeftIcon from '../resources/arrow-bar-left.svg';
import { useEffect, useState } from 'react';
import api from "../services/api";
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router-dom';

function Customers(props) {
    const [clientes, setClientes] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        findAllCustomers();
    }, []);

    const findAllCustomers = () => {
        api
            .get("/cliente/findAll", {
            })
            .then((response) => {
                const newArray = Object.entries(response.data).map(([id, item]) => ({
                    id,
                    ...item
                }));
                const filteredArray = newArray.filter((item) => item.ativo !== "N");
                setClientes(filteredArray);
            })
            .catch((err) => {
                alert("ops! ocorreu um erro" + err);
            });
    }

    const data = {
        columns: [
            {
                label: 'Nome',
                field: 'nome',
                sort: 'asc',
                width: 250
            },
            {
                label: 'Cidade',
                field: 'cidade',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Endereço',
                field: 'endereco',
                sort: 'asc',
                width: 250
            },
            {
                label: 'Email',
                field: 'email',
                sort: 'asc',
                width: 250
            },
            {
                label: 'Telefone',
                field: 'telefone',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Ação',
                field: 'acao',
                sort: 'asc',
                width: 250
            }
        ],
        rows:
            clientes
    };

    const handleClick = id => {
        navigate('/editcustomer', {state:{id}});
    };

    const deleteCustomer = (row) => {
        api
            .delete(`/cliente/remove/${row.id}`)
            .then(() => {
                alert("Cliente excluido!");
                findAllCustomers();
            })
            .catch((err) => {
                alert("ops! ocorreu um erro" + err);
            });
    };

    const handleDelete = (row) => {
        const confirmed = window.confirm(`Tem certeza que deseja excluir cliente ${row.nome} ?`);
        if (confirmed) {
            deleteCustomer(row);
        }
    };

    const rows = data.rows.map(row => {
        row.acao =
            <div className="flex justify-between px-8 py-2">
                <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={() => handleClick(row.id)}>Editar</button>
                <button className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600" onClick={() => handleDelete(row)}>Excluir</button>
            </div>
        return row;
    });

    return (
        <div className="h-screen w-screen relative">
            <MyHeader title="Clientes" />
            <div>
                <MDBDataTable
                    className="max-xl: mx-2 mt-4 p-6 rounded-lg shadow-md"
                    scrollY
                    height="150vh"
                    bordered
                    responsive
                    paging={false}
                    data={{ ...data, rows }}
                />

                <div className="fixed bottom-0 right-0 flex items-center justify-center flex-row-reverse p-4">
                    <MyButton to="/newcustomer" alt="Novo" source={personAddIcon} nome="Novo" />
                    <MyButton to="/home" alt="Voltar" source={arrowBarLeftIcon} nome="Voltar" />
                </div>
            </div>
        </div>
    )
};

export default Customers;