import MyButton from "../components/Button";
import MyHeader from "../components/Header";
import MonthCarousel from "../components/MonthCarroussel";

import "./Customers.css";
import personAddIcon from "../resources/person-add.svg";
import arrowBarLeftIcon from "../resources/arrow-bar-left.svg";
import { useEffect, useState } from "react";
import api from "../services/api";
import { MDBDataTable } from "mdbreact";
import { useNavigate } from 'react-router-dom';

function ListVendas(props) {
  const navigate = useNavigate();

  const date = new Date();
  const separator = `${date.toLocaleDateString("pt-BR", {
    year: "numeric",
    month: "2-digit",
  })}`;
  const formattedSeparator = separator.replace("/", "");
  const [vendasFiltered, setVendasFiltered] = useState();
  const [vendas, setVendas] = useState([]);
  const [months, setMonths] = useState([formattedSeparator]);
  const [index, setIndex] = useState(0);
  const [mes, setMes] = useState(formattedSeparator);

  useEffect(() => {
    vendasFirebase();
  }, []);

  useEffect(() => {
    if (vendas) {
      vendasFirebaseFiltered(mes);
    }
  }, [vendas, mes]);

  const vendasFirebase = () => {
    api
      .get("/venda/findAll", {})
      .then((response) => {
        const input = response.data;
        const vendasNaoFaturadas = {};

        for (const mes in input) {
          for (const vendaId in input[mes]) {
            const venda = input[mes][vendaId];
            if (venda.faturada === false) {
              if (!vendasNaoFaturadas[mes]) {
                vendasNaoFaturadas[mes] = {};
              }
              vendasNaoFaturadas[mes][vendaId] = venda;
            }
          }
        }
        setVendas(vendasNaoFaturadas);
      })
      .catch((err) => {
        console.log(err);
        alert("ops! ocorreu um erro" + err);
      });
  };

  const data = {
    columns: [
      {
        label: "Número",
        field: "numeroVenda",
        sort: "asc",
        width: 200,
      },
      {
        label: "Data",
        field: "data",
        sort: "asc",
        width: 200,
      },
      {
        label: "Cliente",
        field: "nomeCliente",
        sort: "asc",
        width: 200,
      },
      {
        label: "Valor",
        field: "valor",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ação",
        field: "acao",
        sort: "asc",
        width: 250,
      },
    ],
    rows: vendasFiltered,
  };

  const handleClick = (row) => {
    const confirmed = window.confirm(
      `Tem certeza que deseja faturar a Venda ${row.numeroVenda} ?`
    );
    if (confirmed) {
      faturarVenda(row);
    }
  };

  const handleDetalhes = (row) => {
    const venda = removerCampo(row, "acao");
    console.log("Venda: ", venda);
    navigate('/showvenda', {state: 
      {...venda}
    });
  };

  const removerCampo = (objeto, campoParaRemover) => {
    const { [campoParaRemover]: omitido, ...novoObjeto } = objeto;
    return novoObjeto;
  };

  const faturarVenda = (venda) => {
    venda = removerCampo(venda, "acao");
    const vendaFaturada = { ...venda, faturada: true };
    console.log("faturada: ", vendaFaturada);
    if (vendaFaturada) {
      api
        .put(`/venda/update/${venda.idVenda}`, vendaFaturada)
        .then(() => {
          alert("Venda faturada com Sucesso!");
          vendasFirebase();
        })
        .catch((err) => {
          alert("ops! ocorreu um erro" + err);
        });
    }
  };


  const deleteVenda = (venda) => {
    venda = removerCampo(venda, "acao");
      api
      .put(`/venda/remove/${venda.idVenda}`, venda)
      .then((response) => {
        alert('Venda excluída com Sucesso!');
        vendasFirebase();
      })
      .catch(err => {
        alert('ops! ocorreu um erro' + err);
      });
  };

  const vendasFirebaseFiltered = (mes) => {
    const filtro = vendas[mes] ? vendas[mes] : [];

    setVendasFiltered(Object.values(filtro));
    const keysArray = Object.keys(vendas).sort();
    setMonths(keysArray);
    setIndex(keysArray.length);
  };


  const handleDelete = (row) => {
    const confirmed = window.confirm(
      `Tem certeza que deseja excluir a Venda ${row.numeroVenda} ?`
    );
    if (confirmed) {
      deleteVenda(row);
    }
  };

  const handleSelectedMonth = (month) => {
    setMes(month);
  };

  const rows = data.rows
    ? data.rows.map((row) => {
        row.acao = (
          <div className="flex justify-between px-8 py-2">
              <button
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              onClick={() => handleDetalhes(row)}
            >
              Detalhes
            </button>
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => handleClick(row)}
            >
              Faturar
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={() => handleDelete(row)}
            >
              Excluir
            </button>
          </div>
        );
        return row;
      })
    : [];

  return (
    <div className="h-screen w-screen relative">
      <MyHeader title="Lista de Vendas Abertas" />
      <div>
        <MonthCarousel
          onMonthClick={handleSelectedMonth}
          months={months}
          initialIndex={index}
        />
        <MDBDataTable
          className="max-xl: mx-2 mt-4 p-6 rounded-lg shadow-md"
          scrollY
          height="150vh"
          bordered
          responsive
          paging={false}
          data={{ ...data, rows }}
        />

        <div className="fixed bottom-0 right-0 flex items-center justify-center flex-row-reverse p-4">
          {/* <MyButton onClick={handlePDF} alt="Imprimir" source={calendar} nome="Imprimir" /> */}
          <MyButton to="/newvenda" alt="Nova" source={personAddIcon} nome="Nova" />
          <MyButton
            to="/home"
            alt="Voltar"
            source={arrowBarLeftIcon}
            nome="Voltar"
          />
        </div>
      </div>
    </div>
  );
}

export default ListVendas;
