import React from 'react'
import MyButton from './Button';
import boxarrowrightIcon from '../resources/box-arrow-right.svg';
import MySection from './Section';

const UserLogout = () => {
    return (
        
        <div className="h-screen flex flex-col justify-center items-center bg-gray-100">
            <br />
            <br />
            <br />
            <div className="mb-8 text-center">
                <h1 className="text-4xl font-bold text-gray-900">Oops!</h1>
                <h3 className="text-xl font-medium text-gray-700">Usuário não logado</h3>
            </div>
            <br />
            <MySection>
                <MyButton to={"/login"} alt="Voltar" source={boxarrowrightIcon} nome="Voltar" />
            </MySection>
        </div>
    )
}

export default UserLogout;
