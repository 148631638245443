import MySection from "../components/Section";
import MyButton from "../components/Button";
import MyHeader from "../components/Header";
import personAddIcon from "../resources/person-add.svg";
import arrowBarLeftIcon from "../resources/arrow-bar-left.svg";
import ComboBox from "react-responsive-combo-box";
import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import FlatListOrcamento from "../components/FlatListOrcamento";
import FlatList from "../components/FlatList";
import api from "../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import "react-responsive-combo-box/dist/index.css";

function NewVenda(props) {
  const [customer, setCustomer] = useState("");
  const [clientes, setClientes] = useState([]);
  const [clienteNome, setClienteNome] = useState(""); 
  const [showBox, setShowBox] = useState(true);
  const [quantidade, setQuantidade] = useState(1);
  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [productsSale, setProductsSale] = useState([]);
  const [servicesSale, setServicesSale] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    findAllCustomers();
    findAllProducts();
    findAllServices();
  }, []);

    useEffect(() => {
      setTotal(valorProvisorio);
    }, [productsSale, servicesSale]);

  const findAllServices = () => {
    api
      .get("/cadastroserv/findAll", {})
      .then((response) => {
        const newArray = Object.entries(response.data).map(([id, item]) => ({
          id,
          ...item,
        }));
        const filteredArray = newArray.filter((item) => item.inativo === "N");
        setServices(filteredArray);
      })
      .catch((err) => {
        alert("ops! ocorreu um erro" + err);
      });
  };

  const findAllProducts = () => {
    api
      .get("/cadastroprod/findAll", {})
      .then((response) => {
        const newArray = Object.entries(response.data).map(([id, item]) => ({
          id,
          ...item,
        }));
        const filteredArray = newArray.filter((item) => item.inativo === "N");
        setProducts(filteredArray);
      })
      .catch((err) => {
        alert("ops! ocorreu um erro" + err);
      });
  };

  const findAllCustomers = () => {
    api
      .get("/cliente/findAll", {})
      .then((response) => {
        const newArray = Object.entries(response.data).map(([id, item]) => ({
          id,
          ...item,
        }));
        const filteredArray = newArray.filter((item) => item.ativo !== "N");
        const clientes = filteredArray.map((item) => ({
          value: item.id,
          label: item.nome,
        }));
        setClientes(clientes);
      })
      .catch((err) => {
        alert("ops! ocorreu um erro" + err);
      });
  };

  const handleProduct = (novo) => {
    novo.quantidade = quantidade;
    const novoArray = Array.isArray(novo) ? novo : [novo];

    const updatedProducts = [...productsSale];

    novoArray.forEach((item) => {
      const existingItemIndex = updatedProducts.findIndex(
        (p) => p.id === item.id
      );

      if (existingItemIndex !== -1) {
        updatedProducts[existingItemIndex].quantidade += item.quantidade;
      } else {
        updatedProducts.push(item);
      }
    });

    setProductsSale(updatedProducts);
  };

  const handleService = (novo) => {
    novo.quantidade = quantidade;

    const novoArray = Array.isArray(novo) ? novo : [novo];

    const updatedServices = [...servicesSale];

    novoArray.forEach((item) => {
      const existingItemIndex = updatedServices.findIndex(
        (p) => p.id === item.id
      );

      if (existingItemIndex !== -1) {
        updatedServices[existingItemIndex].quantidade += item.quantidade;
      } else {
        updatedServices.push(item);
      }
    });

    setServicesSale(updatedServices);
  };

  const showToastWithGravity = (message) => {
    alert(message);
  };

  const calcularTotais = (objeto) => {
    let totalProd = 0;
    let totalServ = 0;
    let totalGeral = 0;

    const produtosComTotais = objeto.produtos.map((produto) => {
      const total = produto.quantidade * produto.valorUnit;
      totalProd += total;
      return { ...produto, total };
    });

    const servicosComTotais = objeto.servicos.map((servico) => {
      const totalServicos = servico.quantidade * servico.valorUnit;
      totalServ += totalServicos;
      return { ...servico, totalServicos };
    });

    totalGeral = totalProd + totalServ;
    console.log(totalGeral)
    const objetoModificado = {
      ...objeto,
      valor: parseFloat(totalGeral.toFixed(2)),
      produtos: produtosComTotais,
      servicos: servicosComTotais,
    };
    return objetoModificado;
  };

  const valorProvisorio = () => {
    const novaVenda = {
      produtos: productsSale,
      servicos: servicesSale,
    };
    const result = calcularTotais(novaVenda);
    return result.valor;
  };

  const handleQuantidadeChange = (e) => {
    const valorNumerico = parseInt(e.target.value, 10);
    if (!isNaN(valorNumerico)) {
      setQuantidade(valorNumerico);
    } else {
      setQuantidade(e.target.value);
      console.error("O valor inserido não é um número válido.");
    }
  };

  const gravarVenda = async () => {
    setLoading(true);
    if (productsSale.length == 0 && servicesSale.length == 0) {
      setLoading(false);
      return alert("Nenhum produto ou serviço selecionado!");
    }
    const novaVenda = {
      idCliente: customer ? customer : "",
      nomeCliente: clienteNome ? clienteNome : "",
      idVendedor: "",
      produtos: productsSale,
      servicos: servicesSale,
    };
    const final = calcularTotais(novaVenda);

    await api
      .post(`/venda/create`, {
        ...final,
      })
      .then(() => {
        setLoading(false);
        showToastWithGravity("Venda criada com Sucesso!");
        navigate("/home");
      })
      .catch((err) => {
        setLoading(false);
        showToastWithGravity("ops! ocorreu um erro" + err);
      });
  };

  const removeProduct = (productId) => {
    const updatedProducts = productsSale.filter((item) => item.id !== productId);
    setProductsSale(updatedProducts);
  };

  const removeService = (serviceId) => {
    const updatedServices = servicesSale.filter((item) => item.id !== serviceId);
    setServicesSale(updatedServices);
  };

  return loading ? (
    <Loading />
  ) : (
    <div>
      <MyHeader title="Cadastro de Vendas" />
      <form>
        <div>      
          <div className="flex justify-between"> 
          <div className="w-full md:w-1/3 px-4 mb-2">
            <label htmlFor="cliente" className="block mb-1">
              Cliente:
            </label>
            {showBox && (
              <ComboBox
                options={clientes}
                placeholder="Clique para vincular um cliente"
                optionsListMaxHeight={300}
                style={{
                  height: "42px",
                  width: "250px",
                }}
                focusColor="#20C374"
                renderOptions={(option) => (
                  <div className="comboBoxOption">{option.label}</div>
                )}
                onSelect={(option) => {
                  setCustomer(option.value);
                  setClienteNome(option.label);
                  setShowBox(false);
                }}
              />
            )}
            {!showBox && (
              <div className="flex justify-between">
                <input
                  type="text"
                  id="cliente"
                  name="cliente"
                  className="w-full border rounded py-2 px-3"
                  value={clienteNome}
                  disabled
                />
                <button
                  className="p-2 h-10 bg-red-700 text-white rounded hover:bg-blue-600"
                  onClick={() => {
                    setShowBox(true);
                    setCustomer("");
                  }}
                >
                  X
                </button>
              </div>
            )}
            
          </div>

          <div className="md:w-1/6 px-4">
            <label htmlFor="cliente" className="block mb-1">
              Quantidade ao incluir item:
            </label>
            <input
              type="text"
              id="codigo"
              name="codigo"
              className="w-full border rounded py-2 px-3"
              value={quantidade}
              onChange={handleQuantidadeChange}
            />
          </div>
          </div>
          

          <div className="p-5 flex flex-wrap -mx-2">
            <div className="w-full md:w-1/3 px-2 mb-2">
              <label htmlFor="produtos" className="block mb-1">
                Produtos:
              </label>
              <FlatList onButtonClick={handleProduct} data={products} />
            </div>

            <div className="w-full md:w-1/3 px-2 mb-2">
              <label htmlFor="servicos" className="block mb-1">
                Serviços:
              </label>
              <FlatList onButtonClick={handleService} data={services} />
            </div>

            <div className="w-full md:w-1/3 px-2 mb-2">
              <div className="bg-blue-100 p-4 text-black rounded-md shadow-md">
                <p className="text-3xl font-bold mb-4">Orçamento:</p>
                <div className="w-full md:w-1/4 px-2 mb-2">
                  <div>
                    <label htmlFor="produtos" className="block mb-1">
                      Produtos:
                    </label>
                    <FlatListOrcamento onButtonClick={removeProduct} data={productsSale} />
                  </div>

                  <div>
                    <label htmlFor="servicos">Serviços:</label>
                    <FlatListOrcamento onButtonClick={removeService} data={servicesSale} />
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-blue-200 p-4 text-black rounded-md shadow-md mb-2">
              <p className="text-3xl font-bold mb-4">Valor Total</p>
              <div className="flex items-center justify-center">
                <span className="text-xl">R$</span>
                <span className="text-6xl ml-4">{total.toFixed(2)}</span>
              </div>
            </div>
            
          </div>

          <div className="fixed bottom-0 right-0 flex items-center justify-center flex-row-reverse p-4">
            <label htmlFor="">&nbsp;</label>
            <MySection>
              <MyButton
                to="/home"
                alt="Voltar"
                source={arrowBarLeftIcon}
                nome="Voltar"
              />
              <MyButton
                onClick={gravarVenda}
                to=""
                alt="Salvar"
                source={personAddIcon}
                nome="Salvar"
              />
            </MySection>
          </div>
        </div>
      </form>
    </div>
  );
}

export default NewVenda;
