import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// producao
const firebaseConfig = {
    apiKey: "AIzaSyADlsXMAt5EHqdjsoTsVSLUww-ymAA54BI",
    authDomain: "buritama-74f22.firebaseapp.com",
    databaseURL: "https://buritama-74f22-default-rtdb.firebaseio.com",
    projectId: "buritama-74f22",
    storageBucket: "buritama-74f22.appspot.com",
    messagingSenderId: "830578385321",
    appId: "1:830578385321:web:6e453e7dc5a256d9fae4b8",
    measurementId: "G-4ZTKLBTPK8"
  };
// const firebaseConfig = {
//     apiKey: "AIzaSyAf1VOaAl-8Xk0FCsJMMD9XqtqYAMtBU0g",
//     authDomain: "minha-ordem-de-servico.firebaseapp.com",
//     databaseURL: "https://minha-ordem-de-servico.firebaseio.com",
//     projectId: "minha-ordem-de-servico",
//     storageBucket: "minha-ordem-de-servico.appspot.com",
//     messagingSenderId: "456221448848",
//     appId: "1:456221448848:web:a126061af5b0df153c5615",
//     measurementId: "G-F5V9DF44MH"
// };

// const firebaseConfig = {
//     apiKey: "AIzaSyAdsob-xmlvz3JKuKbRt8bJwDAfY5G3nNo",
//     authDomain: "gerenciedesenv.firebaseapp.com",
//     databaseURL: "https://gerenciedesenv-default-rtdb.firebaseio.com",
//     projectId: "gerenciedesenv",
//     storageBucket: "gerenciedesenv.appspot.com",
//     messagingSenderId: "834761960",
//     appId: "1:834761960:web:f2c2be4b811479922e055d",
//     measurementId: "G-MSS4J9SJSG"
//   };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

export default analytics;
